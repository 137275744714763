import React, { useState, useEffect } from 'react'
import Slider from 'react-slick';
import Axios from 'axios';
import Global from '../../../config/global';


function TopGames({isDashboard=false}) {
    
	const [state, setState] = useState({
		isLoading: false,
		footer_slider: []	
	  });

	  useEffect(() => {
		fetchTopGateData();
	}, [],);

	const fetchTopGateData = async () => {

		Axios.get(`${Global.W_BASEURL}front/home/fetchHeaderData`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
				stateHandler("footer_slider", data?.footer_banner);

			}

		})
	}
	const stateHandler = (key, value) => {
		state[key] = value;
		setState({ ...state });
	}

	const settings_mini_slider = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
		prevArrow: <button className="left-slick slick-prev" aria-label="Previous" type="button"><i className="fa-solid fa-angle-left"></i></button>,
    	nextArrow: <button className="right-slick slick-next" aria-label="Next" type="button"><i className="fa-solid fa-angle-right"></i></button>,
		responsive: [
			{
			  breakpoint: 991,
			  settings: {
				slidesToShow: (isDashboard)?4:3,
			  }
			}
		  ]
	};
    
	return (
		<div id="topGames" className="topGames-section pageClass homeSection2"  style={{backgroundImage:'url(/assets/images/topGame.png)'}}>
			<div className="container zIndex2">
				<div className="siteTitle pageClass text-center" style={{display:"none"}}>
					<h2>Top <span className="alternate">Games</span></h2>
				</div>
				<Slider className="topGame-slider" {...settings_mini_slider} style={{paddingLeft:'20px',paddingRight:'20px'}}>
				{state?.footer_slider.map((value) => (
					<div key={value} className="topGame-slide">
						<div className="topGame-imgWrapper pageClass">
							<a>
								<div className="topGame-imgHolder">
									<figure><img style={{"borderRadius": "10px"}} src={`${Global.IMAGE_PATH}${value}`} alt="Amit Design" /></figure >
								</div>
							</a>
						</div>
					</div>
				))}
				</Slider>
			</div>
		</div>
	);

}

export default TopGames;
