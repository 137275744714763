import Axios from "axios"
import {store} from './../redux/store';

export const POST = (url,body) =>{
    try {
        const {buyerAuth} = store.getState();
        const {buyerdata} = buyerAuth;
        let headers = {
            headers: {
                'Authorization': `${buyerdata?.token}` 
            }
        }
        return new Promise((resolve,reject)=>(
            Axios.post(url,body,headers).then((res)=>{
                resolve(res)
            }).catch((err)=>{
                if(err?.response?.status == 401) {
                    store.dispatch({type:'BLOGOUT'});
                    if(window.location.pathname != '/' && window.location.pathname != '/index' && window.location.pathname != '/home') {
                        window.location.href = '/';
                    }
                } else {
                    reject(err)
                }
            })    
        ))
    } catch(err){
        console.log('err')
    }
}

export const GET = (url,token) =>{
    try {
    
        const {buyerAuth} = store.getState();
        const {buyerdata} = buyerAuth;
        let headers = {
            headers: {
                'Authorization': `${buyerdata?.token}` 
            }
        }
        
        return new Promise((resolve,reject)=>(
            Axios.get(url,headers).then((res)=>{
                resolve(res)
            }).catch((err)=>{
                if(err?.response?.status == 401) {
                    store.dispatch({type:'BLOGOUT'});
                    if(window.location.pathname != '/' && window.location.pathname != '/index' && window.location.pathname != '/home') {
                        window.location.href = '/';
                    }
                } else {
                    reject(err)
                }
            })    
        ))
    } catch(err){
        console.log('err')
    }
}
