import React, { useState, useEffect, memo } from 'react'

function Marquee({marquee=''}) {
	return (
        <div className="notificationBar">
            <marquee>{marquee}</marquee>
        </div>
	);

}

export default Marquee;
