import { io } from 'socket.io-client';
import global from './global';

const SOCKET_SERVER_URL = global.SOCKETURL; // Change this to your server URL
let socket;

export const initSocket = (login_id) => {
    // if(socket?.connected != true){
    //     console.log(socket?.connected ,'///////////--------------/')
        socket = io(SOCKET_SERVER_URL);
    // } else {
    //     console.log(socket?.connected ,'///////////////////////')
    // }
    
    socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
    });
    
    socket.emit('login',{user_id:login_id});
    return socket;
};

export const getSocket = () => {
    if (!socket) {
        console.log("Socket not initialized. Call initSocket first.");
        return false;
    }
    return socket;
};

export const disconnectSocket = (socketCLient) => {
    if (socket) {
        if(socketCLient) {
            socket.emit('logout',socketCLient)
        }
        
        socket.disconnect();
    }
};


// export const socketIo = io(global.SOCKETURL); // Adjust if your server URL is different;