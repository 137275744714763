import CardSlider from "./cardSlider";

const RenderLiveCards = ({t1data={},t3data={},liveCardKey={},bfCardList}) =>{
    let liveCards = (Object.keys(bfCardList)?.length)?bfCardList:t1data;
    let liveCardArray = [];
    let liveCardArrayB = [];

    if(t3data?.aall){
        liveCardArray = t3data?.aall?.split(',');
    }
    if(t3data?.ball){
        liveCardArrayB = t3data?.ball?.split(',');
    }

    let dealerCard = {card0:'blank',card1:'blank',card2:'blank'};
    if(liveCardKey?.dealer){
        let cardsList = t1data?.Cards?.split(',');
        if(cardsList){
            // Loop through the values and distribute them alternately
            for (let i = 0; i < cardsList.length; i++) {
                let card = cardsList[i];
                if(card != '1'){
                    if(i >= 3){
                        if (i % 2 === 0) {
                            liveCardArray.push(card);  // Even index (0, 2, 4, ...)
                        } else {
                            liveCardArrayB.push(card);  // Odd index (1, 3, 5, ...)
                        }
                    } else {
                        dealerCard[`card${i}`] = card;
                    }
                }
            }
        }
    }

    if(liveCardKey?.dealer){
        return (
            <div className='LiveCards' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <div>
                            <div style={{color:'#FFFFFF',marginRight:'10px'}}>A</div>
                            <div style={{color:'#FFFFFF',marginRight:'10px'}}>B</div>
                        </div>
                        <div>
                            <img src={`/assets/images/casino/${dealerCard?.card0}.png`} className={(dealerCard?.card0 != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>
                        </div>
                    </div>
                    <div>
                        <div className="mb-1">
                            <img src={`/assets/images/casino/${dealerCard?.card2}.png`} className={(dealerCard?.card0 != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>
                        </div>
                        
                        <div >
                            <img src={`/assets/images/casino/${dealerCard?.card1}.png`} className={(dealerCard?.card1 != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>
                        </div>
                    </div>
                </div>
                <div>
                    <CardSlider style={{marginBottom:'3px'}} data={liveCardArray}/>
                    <CardSlider style={{marginBottom:'3px'}} data={liveCardArrayB}/>
                </div>
            </div>
        )
    }
    else if(liveCardKey?.slider){
        return (
            <div className='LiveCards'>
                <CardSlider data={liveCardArray}/>
                <CardSlider data={liveCardArrayB}/>
            </div>
        )
    }else {
        return (
            <div className='LiveCards' >
                {/* {(liveCardKey?.levelA)&& */}
                <>
                    <div style={{color:'#FFFFFF'}}>
                        {liveCardKey?.levelA}
                    </div>
                    <div style={{marginBottom:'10px'}}>
                        {liveCardKey?.levelACards?.map((v,i)=>{
                            let card = (liveCards?.[v])?(liveCards?.[v] && liveCards?.[v] != '1')?liveCards?.[v]:'blank':'';
                            if(card){
                                return (<img src={`/assets/images/casino/${card}.png`} className={(card != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>)
                            }
                        })}
                    </div>
                </>
                {/* } */}
    
                {/* {(liveCardKey?.levelB)&& */}
                <>
                    <div style={{color:'#FFFFFF'}}>
                        {liveCardKey?.levelB}
                    </div>
                    <div>
                        {liveCardKey?.levelBCards?.map((v,i)=>{
                            let card = (liveCards?.[v])?(liveCards?.[v] && liveCards?.[v] != '1')?liveCards?.[v]:'blank':'';
                            if(card){
                                return (<img src={`/assets/images/casino/${card}.png`} className={(card != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>)
                            }
                        })}
                    </div>
                    </>
                {/* } */}
            </div>
        )
    }
    
}

export default RenderLiveCards;
