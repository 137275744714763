import { GET, POST } from "config/api";
import global from "config/global";
import { memo, useEffect, useState } from "react";
import ResultModal from "./ResultModel";
import { useNavigate } from "react-router-dom";

const memoCompare = (prevProps, nextProps) =>{
	const { casino_id } = nextProps;
	const { casino_id : prevcasino_id } = prevProps;
	return (casino_id == prevcasino_id);
}

const RenderCasinoResult = memo(({casino_id}) =>{
    const history = useNavigate()
    const [resultList,setResultList] = useState([]);
    const [modalData,setModalData] = useState({});

    useEffect(()=>{
        fetchResult(casino_id)
    },[casino_id]);

    const fetchResult = (casino_id)=>{
        let body = {
            casino_id:casino_id,
            index:0,
            limit:10,
        }
        POST(`${global.CASINO_WEB}bat/result`,body).then((res)=>{
            if(res.data.status == 200) {
                setResultList(res?.data?.data);
            } 
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    const resultTitle = (result)=>{
        let {dt20,dt20b,dtl20,lucky7,lucky7eu,ddb,t20Odds,worliinstant,testtp,Tp1Day,Andarbahar,Andarbahar2,dragontiger1Day,warcasino} = global?.CASINO_GAME_TYPE;
        if(casino_id == dt20 || casino_id == dt20b || casino_id == dragontiger1Day) {
            if(result == 1){
                return 'D';
            } else {
                return 'T';
            }
        } else if(casino_id == Andarbahar || casino_id == Andarbahar2) {
                return 'R';
        } else if(casino_id == dtl20) {
            if(result == '1') {
                return 'D';
            } if(result == '21') {
                return 'T';
            } else {
                return 'L';
            }
        } else if(casino_id == testtp) {
            if(result == '11') {
                return 'T';
            } if(result == '21') {
                return 'L';
            } else {
                return 'D';
            }
        } else if (casino_id == warcasino || casino_id == worliinstant){
            return 'R';
        } else if(casino_id == lucky7 || casino_id == lucky7eu) {
            if(result == 2){
                return 'H';
            } else {
                return 'L';
            }
        } else if(casino_id == 'AAA') {
            if(result == '1') {
                return 'A';
            } if(result == '2') {
                return 'B';
            } else {
                return 'C';
            }
        }  else if(casino_id == ddb) {
            if(result == '1') {
                return 'A';
            } if(result == '2') {
                return 'B';
            }if(result == '3') {
                return 'C';
            } if(result == '4') {
                return 'D';
            } if(result == '5') {
                return 'E';
            } else {
                return 'F';
            }
        } else if(casino_id == t20Odds || casino_id == Tp1Day) {
            if(result == 1){
                return 'A';
            } else {
                return 'B';
            }
        }
    }

    if(resultList?.length > 0){
        return (
            <>
                <h5 style={{color:'#000'}}>Last Results</h5>
                <div className='casino-result'>
                    {resultList?.map((v,i)=><div onClick={()=>{setModalData(v)}}>{resultTitle(v?.result)}</div>)}
                    <div onClick={()=>{
                        history(`/casinoresult/${casino_id}`)
                    }}>
                        <i className="fa fa-circle " style={{marginRight:'3px',fontSize:'5px'}} />
                        <i className="fa fa-circle " style={{marginRight:'3px',fontSize:'5px'}} />
                        <i className="fa fa-circle " style={{marginRight:'3px',fontSize:'5px'}} />
                    </div>
                    {(modalData?.mid != undefined)&&
                        <ResultModal data={modalData} onClose={()=>{
                            setModalData({});
                        }}/>
                    }
                </div>
            </>
        )
    } else {
        return null;
    }
    
},memoCompare)

export default RenderCasinoResult;
