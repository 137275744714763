import React, { useState, useEffect } from 'react'
import Global from '../../../../config/global';
import { useSelector } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTv, faFlag, faBars, faStar, faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import { POST,GET } from 'config/api';


function Stackes(props) {
	const buyerdata = useSelector((state) => state.buyerAuth.buyerdata);
    library.add(faTv, faFlag, faBars, faStar, faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);    
    
    const [stackArray,setStackArray] = useState([
        {key:'stakeLable1',value:'',title:'Stake Label 1',error:''},
        {key:'stakeLableVal1',value:'',title:'Stake Value 1',error:''},
        {key:'stakeLable2',value:'',title:'Stake Label 2',error:''},
        {key:'stakeLableVal2',value:'',title:'Stake Value 2',error:''},
        {key:'stakeLable3',value:'',title:'Stake Label 3',error:''},
        {key:'stakeLableVal3',value:'',title:'Stake Value 3',error:''},
        {key:'stakeLable4',value:'',title:'Stake Label 4',error:''},
        {key:'stakeLableVal4',value:'',title:'Stake Value 4',error:''},
        {key:'stakeLable5',value:'',title:'Stake Label 5',error:''},
        {key:'stakeLableVal5',value:'',title:'Stake Value 5',error:''},
        {key:'stakeLable6',value:'',title:'Stake Label 6',error:''},
        {key:'stakeLableVal6',value:'',title:'Stake Value 6',error:''},
        {key:'stakeLable7',value:'',title:'Stake Label 7',error:''},
        {key:'stakeLableVal7',value:'',title:'Stake Value 7',error:''},
        {key:'stakeLable8',value:'',title:'Stake Label 8',error:''},
        {key:'stakeLableVal8',value:'',title:'Stake Value 8',error:''},
    ]);

    const [state, setState] = useState({
        isLoading: false,
      });

      useEffect(() => {
        customerStackData();
      }, [],);
      const customerStackData = async () => {
        stateHandler("isLoading", true);

        await GET(`${Global.W_BASEURL}customer/match/fetchCustomerStackesData/${buyerdata.id}`).then((res)=>{
          if (res.data.status == 200) {
            stateHandler("isLoading", false);
            let data = res.data.data;
            if(data?.stackes){
                let stackList = [];
                let i = 1;
                let lebalCount = 0;
                for(let item in data?.stackes){
                    if(item?.indexOf('stack_lable') != -1){
                        if(item?.indexOf('stack_lable_val')!= -1){
                            let val = data?.stackes[`stack_lable_val_${i}`];
                            stackList?.push({
                                key:`stakeLableVal${i}`,
                                value:(val && val != 'undefined')?val:'',
                                title:`Stake Label Value ${i}`,
                                error:''
                            })
                            lebalCount = lebalCount + 1;
                        } else {
                            let val = data?.stackes[`stack_lable_${i}`];
                            stackList?.push({
                                key:`stakeLable${i}`,
                                value:(val && val != 'undefined')?val:'',
                                title:`Stake Label ${i}`,
                                error:''
                            })
                            lebalCount = lebalCount + 1;
                        }
                        if(lebalCount == 2){
                            i++;
                            lebalCount = 0;
                        }
                    }
                }
                setStackArray(stackList);
            }
          }
        })
      }

      const stateHandler = (key, value) => {
        state[key] = value;
        setState({ ...state });
      }
      
      const saveStackes = () => {
        if(validation()){
            stateHandler("isLoading", true);
            let body = {
                userid:buyerdata.id,
            };
            
            let i = 1;
            let lebalCount = 0;

            for(let item of stackArray){
                if(item?.key?.indexOf('stakeLableVal') != -1){
                    body[`stack_lable_val_${i}`] = item?.value;
                    lebalCount = lebalCount + 1;
                } else {
                    body[`stack_lable_${i}`] = item?.value;
                    lebalCount = lebalCount + 1;
                }
                if(lebalCount == 2){
                    i++;
                    lebalCount = 0;
                }
            }

              POST(Global.W_BASEURL+'customer/match/editStackes',body).then((res)=>{
                stateHandler("isLoading", false);
                    if(res.data.status==200)
                    {
                        toast.success("Request Updated Successfully",{autoClose: 2000});
                    }
                }).catch((err)=>{
                    console.log(err);
                });
        }
      }
      const validation = () => {
        let data  = [...stackArray];
        let isError = false;
        for(let item of data){
            if(item?.value == ''){
                item.error = `Please Enter ${item?.title}`
                isError = true;
            } else {
                item.error = '';
            }
        }

        setStackArray(data);

        if (isError == false){
            return true;
        }
        else {
            return false;
        }
    }


    const setStakeValue = (index,label,value) =>{
        const re = /^[0-9\b]+$/;
        if ((value === '' || re.test(value) || label?.indexOf('stakeLableVal') == -1)) {
            let data = [...stackArray]
            if(data[index]){
                data[index].value = value;
            }
            setStackArray(data);
        }
    }


    return (
        <div className='col-lg-10'>
            <div className='pageRight-mainWrapper'>
            <div className="dashborad-content-box">
                <div className="category-title">
                    <h2>Edit <span>Stakes</span></h2>
                </div>
                <div className="profit-loss-filter">
                    <div className="row">
                        
                        {stackArray?.map((v,i)=>(
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <label>{v?.title}</label>
                                    <input type="text" className="form-control" value={v?.value} placeholder={v?.title} onChange={(event) => { setStakeValue(i,v?.key,event.target.value) }} />
                                    <span style={{ 'color': 'red' }}>{v?.error}</span>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                    <div className="link-btn">
                        <a href="javascript:void(0);" onClick={() => { saveStackes() }}><i className="fa-solid fa-floppy-disk mr10" /> Save</a>
                    </div>
                </div>
            </div>
            </div>
    </div>
    );

}
export default Stackes;