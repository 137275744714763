import React, { useState, useEffect } from 'react'
import Header from "../../includes/header";
import Footer from "../../includes/footer";
import Global from '../../../../config/global';
import Axios from 'axios';


function About_us(props) {
  const [state, setState] = useState({
    title: 'About Us',
    matter: 'About Us',
    image: 'aboutus.jpg'
  });
  useEffect(() => {
    fetchCmsPage();
  }, [],);

  const fetchCmsPage = async () => {
    let body = {
      page_type: 'About Us'
    };
    const response = await Axios.post(Global.W_BASEURL + 'front/home/fetchCmsPage', body).then((res) => {
      if (res.data.status == 200) {
        let data = res.data.data;
        console.log('res.data',data);

        stateHandler("title", data.title);
        stateHandler("matter", data.matter);
        stateHandler("image", data.image);
      }
    })
  }
  const stateHandler = (key, value) => {
    state[key] = value;
    setState({ ...state });
  }

  return (
    <div className='col-lg-10'>
      <div className='pageRight-mainWrapper'>
        <div className="row">
            <div className="col-md-8 align-self-center">
              <div className="about-content-box">
                <div className="category-title">
                  <h2><span>{state.title}</span></h2>
                </div>
                <p>{state.matter}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="about-pic-container">
                <img src={`${Global.IMAGE_PATH}${state.image}`} alt="" />
              </div>
            </div>
          </div>
          </div>
      </div>
  );
}
export default About_us;
