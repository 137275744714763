import React, { useState, useEffect } from 'react'
import { POST } from 'config/api';
import global from 'config/global';
import Pagination from "react-js-pagination";
import { useParams } from 'react-router-dom';
import { useLoader } from 'component/loader/loader';
import ResultModal from './component/ResultModel';

function CasinoResult() {
    const {showLoader} = useLoader()
    const {casino_id} = useParams()
    const [modalData,setModalData] = useState({});

    const [resultList,setresultList] = useState([]);
    const [totalResult,settotalResult] = useState(0);
    const [currentIndex,setcurrentIndex] = useState(0);

    useEffect(()=>{
        fetchCasinoResult(casino_id)
    },[casino_id])

    const fetchCasinoResult = (casino_id,index=0) => {
        showLoader(true)
        let body = {
            index:index,
            limit:25,
            casino_id:casino_id
        }

        POST(`${global.CASINO_WEB}bat/result`,body).then((res)=>{
            showLoader(false)
            if(res.data.status == 200) {
                setresultList(res?.data?.data);
                settotalResult(res?.data?.total);
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }


    const resultTitle = (result)=>{
        let {dt20,dt20b,dtl20,lucky7,lucky7eu,ddb,t20Odds,worliinstant,testtp,Tp1Day,dragontiger1Day,Andarbahar,Andarbahar2,warcasino} = global?.CASINO_GAME_TYPE;
        if(casino_id == dt20 || casino_id == dt20b || casino_id == dragontiger1Day) {
            if(result == 1){
                return 'Dragon';
            } else {
                return 'Tiger';
            }
        } if(casino_id == Andarbahar || casino_id == Andarbahar2) {
            if(result == 1){
                return 'A';
            } else {
                return 'B';
            }
        } else if(casino_id == dtl20) {
            if(result == '1') {
                return 'Dragon';
            } if(result == '21') {
                return 'Tiger';
            } else {
                return 'Lion';
            }
        } else if(casino_id == testtp) {
            if(result == '11') {
                return 'Tiger';
            } if(result == '21') {
                return 'Lion';
            } else {
                return 'Dragon';
            }
        } else if (casino_id == warcasino || casino_id == worliinstant){
            return 'R';
        } else if(casino_id == lucky7 || casino_id == lucky7eu) {
            if(result == 2){
                return 'High Card';
            } else {
                return 'Low Card';
            }
        } else if(casino_id == 'AAA') {
            if(result == '1') {
                return 'Amar';
            } if(result == '2') {
                return 'Akbar';
            } else {
                return 'Anthony';
            }
        }  else if(casino_id == ddb) {
            if(result == '1') {
                return 'A';
            } if(result == '2') {
                return 'B';
            }if(result == '3') {
                return 'C';
            } if(result == '4') {
                return 'D';
            } if(result == '5') {
                return 'E';
            } else {
                return 'F';
            }
        } else if(casino_id == t20Odds || casino_id == Tp1Day) {
            if(result == 1){
                return 'Player A';
            } else {
                return 'Player B';
            }
        }
    }


    return (
        <div className="col-lg-10">
            <div className="liveGame-section pageClass table-responsive ">
                <div className="liveGame-titleBox pageClass">
                    <div className="siteTitle pageClass pb-0">
                        <h2>Casino Result</h2>
                    </div>
                </div>
                <table className="table table-dark matchBet-table font-14 ">
                    <thead>
                        <tr>
                            <th style={{width:'20px'}}>Round ID</th>
                            <th>Winner</th>
                        </tr>
                    </thead>
                    <tbody>
                    {resultList?.map((item,index)=>(
                        <tr onClick={()=>{
                            setModalData(item)
                        }}>
                            <td  style={{width:'20px'}}>{(item.mid)?item.mid:'-'}</td>
                            <td>{resultTitle(item?.result)}</td>
                        </tr>
                    ))}
                        
                        {(resultList?.length == 0)&&
                        <tr>
                            <td colSpan="4">No Record Found!</td>
                        </tr>
                        }

                    <Pagination
                        activePage={currentIndex}
                        itemsCountPerPage={25}
                        totalItemsCount={totalResult}
                        pageRangeDisplayed={10}
                        onChange={(v)=>{
                            fetchCasinoResult(casino_id,10 * v)
                            setcurrentIndex(v)
                        }}
                        />
                    </tbody>
                    {(modalData?.mid != undefined)&&
                        <ResultModal data={modalData} onClose={()=>{
                            setModalData({});
                        }}/>
                    }
                </table>
            </div> 
        </div>
    );

}
export default CasinoResult;