import React, { useState, useEffect } from 'react'
import Global from '../../../../config/global';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { POST,GET } from 'config/api';

function Terms(props) {

    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);

    const [state, setState] = useState({
        isLoading: false,
        title:'',
        matter:''
    });
    useEffect(() => {
        customerRuleData();
      }, [],);

      const customerRuleData = async () => {
        stateHandler("isLoading", true);

        await GET(`${Global.W_BASEURL}customer/auth/fetchCustomerCmsData/9`).then((res)=>{
          if (res.data.status == 200) {
            let data = res.data.data;
                 stateHandler("title",data[0].title);
                 stateHandler("matter",data[0].matter);
          }
        })
        stateHandler("isLoading", false);

      }
      const stateHandler = (key, value) => {
        state[key] = value;
        setState({ ...state });
      }
    return (
        <div className='col-lg-10'>
          <div className='pageRight-mainWrapper'>
            <div className="dashborad-content-box">
                <div className="category-title">
                    <h2><span>{state?.title}</span></h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: state?.matter }} />
            </div>
            </div>
        </div>
    );


}
export default Terms;