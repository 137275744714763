import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';



function Profile(props) {
	const {buyerdata,siteConfig} = useSelector((state) => state.buyerAuth);
    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);



	const getWallet = (type) =>{
		let exposure = (buyerdata?.exposure_bal)?buyerdata?.exposure_bal:0;
		let wallet = (buyerdata?.rel_wallet)?buyerdata?.rel_wallet:0
		return (type=='exp')?exposure:(type == 'total')?wallet:wallet-exposure;
	}
    const dispatch = useDispatch();

    const logout = ()=>{
		dispatch({type:'BLOGOUT'});
	}
    return (
        <div className="col-lg-10">
            <div className='pageRight-mainWrapper'>
                <div className="main-content-wrapper">
                    <div className="profileMain-container pageClass">
                        <div className="profileInfo-table pageClass">
                            <table className="table table-dark table-hover table-striped table-bordered">
                                <tr>
                                    <th>{buyerdata.name}</th>
                                    <td><Link className='rightNavbarText' to="/change_password">Change Password</Link></td>
                                </tr>
                                <tr>
                                    <th>Wallet Balance</th>
                                    <td>{getWallet('total')}</td>
                                </tr>
                                <tr>
                                    <th>Available Balance </th>
                                    <td>{getWallet()}</td>
                                </tr>
                                <tr>
                                    <th>Winnings</th>
                                    <td>+13,500</td>
                                </tr>
                                <tr>
                                    <th>Exposure</th>
                                    <td className="lossText">{getWallet('exp')}</td>
                                </tr>
                                <tr>
                                    <th colSpan="2">Bonus</th>
                                </tr>
                                <tr>
                                    <th colSpan="2">Total Rolling</th>
                                </tr>
                                <tr>
                                    <th colSpan="2">Remaining Rolling</th>
                                </tr>
                            </table>
                        </div>
                        <div className="profileUser-box pageClass">
                            <div className="row gx-2">
                                <div className="col-6">
                                    <div className="profileItem-box">
                                        <Link className='rightNavbarText' to="/deposit_history">
                                            <img src="images/profile-icon1.png" alt="Vasudev" />
                                            <p>Account <br/> Statement</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="profileItem-box">
                                        <Link className='rightNavbarText' to="/deposit_history">
                                            <img src="images/profile-icon2.png" alt="Vasudev" />
                                            <p>Bank <br/> Transfer</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="profileItem-box">
                                        <Link className='rightNavbarText' to="/bat_history">
                                            <img src="images/profile-icon3.png" alt="Vasudev" />
                                            <p>Deposit <br/> And Withdraw</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="profileItem-box">
                                        <Link className='rightNavbarText' to="/bat_history">
                                            <img src="images/profile-icon4.png" alt="Vasudev" />
                                            <p>Profit <br/> And Loss</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="profileItem-box">
                                        <Link className='rightNavbarText' to="/UpdateProfile">
                                            <img src="images/profile-icon5.png" alt="Vasudev" />
                                            <p>Edit <br/> Profile</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="profileItem-box">
                                        <Link className='rightNavbarText' to="/bat_history">
                                                <img src="images/profile-icon6.svg" alt="Vasudev" />
                                                <p>Notification <br/> History</p>
                                        </Link>
                                    </div>
                                </div>
                                <div onClick={()=>{
                                    logout();
                                }} className="col-12 text-center mt-2 mb-2">
                                    <div className="link-btn1"><a href="javascript:void(0);"><i className="fa-solid fa-right-to-bracket"></i> Log Out</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default Profile;