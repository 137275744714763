import global from "config/global";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CardSlider from "./cardSlider";

const ResultModal = ({onClose,data={}}) =>{
	const breakCardColor = (value) =>{
		const cardColorCode = value?.slice(-2);
		// Get the rest of the string
		let card = value?.slice(0, -2);
		let realCard = card;

		if(card == 'J') {
			card = 11;
		} else if(card == 'Q') {
			card = 12;
		} else if(card == 'K') {
			card = 13;
		} else if(card == 'A') {
			card = 1;
		} 

		let colorName = 'Red';
		if(cardColorCode == 'HH' || cardColorCode == 'CC'){
			colorName = 'Black'
		}

		let evenOdd = (parseFloat(card) % 2 == 0)?'even':'odd'
			
		return {
			card:parseInt(card),
			cardColor:colorName,
			cardColorCode:cardColorCode,
			evenOdd:evenOdd,
			fullcard:value,
			realCard:realCard
		}
	}

	const getHeading = () =>{
		let {dt20,dt20b,dtl20,dragontiger1Day,AAA,Tp1Day,worliinstant,t20Odds,ddb,testtp,lucky7,lucky7eu,warcasino} = global.CASINO_GAME_TYPE;
		if(data?.gtype == dt20) {
			return '20-20 Dragon Tiger Result';
		} else if(data?.gtype == dt20b) {
			return '20-20 Dragon Tiger B Result';
		} else if(data?.gtype == dtl20) {
			return '20-20 Dragon Tiger Lion Result';
		} else if(data?.gtype == dragontiger1Day) {
			return '1 Day Dragon Tiger Result';
		} else if(data?.gtype == AAA) {
			return 'Amar Akbar Anthony Result';
		} else if(data?.gtype == Tp1Day) {
			return 'Teen Patti 1 Day Result';
		} else if(data?.gtype == worliinstant) {
			return 'Instant Worli Result';
		} else if(data?.gtype == t20Odds) {
			return 'Teen 20-20 Result';
		} else if(data?.gtype == ddb) {
			return 'Bollywoord Result';
		} else if(data?.gtype == testtp) {
			return 'Teen Test Result';
		} else if(data?.gtype == lucky7) {
			return 'Lucky 7 Result';
		} else if(data?.gtype == lucky7eu) {
			return 'Lucky 7 B Result';
		} else if(data?.gtype == warcasino) {
			return 'War Casino Result';
		}
	}

	const gettime = () =>{
		if(data?.created_at) {
			return moment(data?.created_at).format('DD/MM/YYYY HH:mm:ss A');
		}
	}
	
	const colorCodeName = (code) => {
		if(code == 'DD') return 'Heart';
		if(code == 'SS') return 'Diamond';
		if(code == 'HH') return 'Spade';
		if(code == 'CC') return 'Club';
	}

	const calculateResult = () =>{
		let {dt20,dt20b,dtl20,dragontiger1Day,Andarbahar,AAA,Tp1Day,worliinstant,t20Odds,ddb,testtp,lucky7,lucky7eu,warcasino} = global.CASINO_GAME_TYPE;
		let result = data?.result;
		let t1 = (data?.t1)?JSON.parse(data?.t1):{};
		t1 = (t1?.length)?t1[0]:{};
		
		let cards = {
			card1 : breakCardColor(t1?.C1),
			card2 : breakCardColor(t1?.C2),
			card3 : breakCardColor(t1?.C3),
			card4 : breakCardColor(t1?.C4),
			card5 : breakCardColor(t1?.C5),
			card6 : breakCardColor(t1?.C6),
			card7 : breakCardColor(t1?.C7),
			card8 : breakCardColor(t1?.C8),
			card9 : breakCardColor(t1?.C9),
		}

		if(data?.gtype == dt20 || data?.gtype == dt20b || data?.gtype == dragontiger1Day) {
			return (
				<>
					{['1','2'].map((v,i)=>(
						<div className="col-md-4 resultwinnerWraper" style={{borderRight:'2px solid #666'}}>
							{(result == v)&&
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
							}
							<div className="text-center">
								<div>{(v=='1')?'Dragon':'Tiger'}</div>
								<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
							</div>
						</div>
					))}
					
					<div className="col-md-4 resultDetailBox">
						<div><span>Winner</span> : {(result == 1)?'Dragon':'Tiger'} </div>
						<div><span>Pair </span>: {(cards?.card1?.card == cards?.card2?.card)?'Yes':'No'}</div>
						<div><span>Odd/Even </span>: {`D : ${cards?.card1?.evenOdd} | T : ${cards?.card2?.evenOdd}`}</div>
						<div><span>Color </span>: {`D : ${cards?.card1?.cardColor} | T : ${cards?.card2?.cardColor}`}</div>
						{(data?.gtype == dragontiger1Day)?
						<div><span>Suit </span>: {`D : ${colorCodeName(cards?.card1?.cardColorCode)} | T : ${colorCodeName(cards?.card2?.cardColorCode)}`}</div>
						:
						<div><span>Card </span>: {`D : ${cards?.card1?.realCard} | T : ${cards?.card2?.realCard}`}</div>
						}
					</div>
				</>
			)
		} else if(data?.gtype == Andarbahar) {
			let liveCardArray = [];
			let liveCardArrayB = [];
			let t3 = (data?.t3)?JSON.parse(data?.t3):[];
			if(t3?.[0]?.aall){
				liveCardArray = t3?.[0]?.aall?.split(',');
			}
			if(t3?.[0]?.ball){
				liveCardArrayB = t3?.[0]?.ball?.split(',');
			}
			return (
				<div style={{textAlign:'center',marginTop:'30px'}}>
					<div>Andar</div>
					<CardSlider type={'full'} defaultLength={13} data={liveCardArray}  />
					<div>Bahar</div>
					<CardSlider type={'full'} defaultLength={13} data={liveCardArrayB}  />
				</div>
			)
		} else if(data?.gtype == dtl20) {
			return (
				<>
					{['1','2','3'].map((v,i)=>(
						<div className="col-md-2 resultwinnerWraper" style={{borderRight:'2px solid #666'}}>
							{(result == v)&&
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
							}
							<div className="text-center">
								<div>{(v=="1")?'Dragon':(v=="2")?'Tiger':'Lion'}</div>
								<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
							</div>
						</div>
					))}
					<div className="col-md-6 resultDetailBox">
						<div><span>Winner</span> : {(result == "1")?'Dragon':(result == "2")?'Tiger':'Lion'} </div>
						<div><span>Red/Black </span>: {`D : ${cards?.card1?.cardColor} | T : ${cards?.card2?.cardColor} | L : ${cards?.card3?.cardColor}`}</div>
						<div><span>Odd/Even </span>: {`D : ${cards?.card1?.evenOdd} | T : ${cards?.card2?.evenOdd} | L : ${cards?.card3?.evenOdd}`}</div>
						<div><span>Card </span>: {`D : ${cards?.card1?.realCard} | T : ${cards?.card2?.realCard} | L : ${cards?.card3?.realCard}`}</div>
					</div>
				</>
			)
		} else if(data?.gtype == warcasino) {
			cards = {...cards,
				card4 : breakCardColor(t1?.C4),
				card5 : breakCardColor(t1?.C5),
				card6 : breakCardColor(t1?.C6),
				card7 : breakCardColor(t1?.C7),
			}
		
			let warResult = result?.split(',')
			return (
				<>
				{['1','2','3','4','5','6','7'].map((v,i)=>(
					<div className="col-md-1" style={{borderRight:'2px solid #666'}}>
						<div className="text-center">
							<div>{v}</div>
							<img src={`/assets/images/casino/${cards[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
						</div>
						{(warResult?.indexOf(v) != -1)?
							<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
							:<div className="casinoResultImg winner"/>
						}
					</div>
				))}

					<div className="col-md-5 resultDetailBox">
						<div><span>Winner</span> : {result?.replaceAll(',',' ')} </div>
						<div><span>Color </span>: {['1','2','3','4','5','6','7'].map((v,i)=>(`${v} : ${cards[`card${v}`]?.cardColor} | `))}</div>
						<div><span>Even/Odd </span>: {['1','2','3','4','5','6','7'].map((v,i)=>(`${v} : ${cards[`card${v}`]?.evenOdd} | `))}</div>
						<div><span>Suit </span>: {['1','2','3','4','5','6','7'].map((v,i)=>(`${v} : ${colorCodeName(cards[`card${v}`]?.cardColorCode)} | `))}</div>
					</div>
				</>
			)
		} else if(data?.gtype == lucky7 || data?.gtype == lucky7eu) {
			return (
				<>
						<div className="col-md-8 resultwinnerWraper" style={{borderRight:'2px solid #666'}}>
							{/* {(result == v)&&
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
							} */}
							<div className="text-center">
								{/* <div>{(v=="1")?'Low':'High'}</div> */}
								<img src={`/assets/images/casino/${cards?.card1?.fullcard}.png`} className="casinoResultImg"/>
							</div>
						</div>
					<div className="col-md-4 resultDetailBox">
						<div><span>Winner</span> : {(result == "1")?'Low Card':'High Card'} </div>
						<div><span>Odd/Even </span>: {cards?.card1?.evenOdd}</div>
						<div><span>Color </span>: {cards?.card1?.cardColor}</div>
						<div><span>Card </span>: {cards?.card1?.realCard}</div>
					</div>
				</>
			)
		} else if(data?.gtype == AAA) {
			return (
				<>
						<div className="col-md-8 resultwinnerWraper" style={{borderRight:'2px solid #666'}}>
							<div className="text-center">
								{/* <div>{(v=="1")?'Low':'High'}</div> */}
								<img src={`/assets/images/casino/${cards?.card1?.fullcard}.png`} className="casinoResultImg"/>
							</div>
						</div>
					<div className="col-md-4 resultDetailBox">
						<div><span>Winner</span> : {(result == "1")?'Amar':(result=='2')?'Akbar':'Anthony'} </div>
						<div><span>Odd/Even </span>: {cards?.card1?.evenOdd}</div>
						<div><span>Color </span>: {cards?.card1?.cardColor}</div>
						<div><span>Under/Over </span>: {(cards?.card1?.realCard==7)?'Tie':(cards?.card1?.realCard > 7)?'Over 7':'Under 7'}</div>
						<div><span>Card </span>: {cards?.card1?.realCard}</div>
					</div>
				</>
			)
		}  else if(data?.gtype == worliinstant) {
			return (
				<>
					{[1,2,3]?.map((v,i)=>(
						<div className="col-md-3 resultwinnerWraper"  style={{padding:'10px'}}>
							<div className="text-center">
								<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
							</div>
						</div>
					))}
					<div className="col-md-3">
					</div>
				</>
			)
		} else if(data?.gtype == ddb) {
			let winner = 'Don';
			if(result == "2") winner='Amar Akbar Anthony';
			if(result == "3") winner='Sahib Bibi Aur Gulam';
			if(result == "4") winner='Dharam Veer';
			if(result == "5") winner='Kis Kis Ko Pyar Karoon';
			if(result == "6") winner='Ghulam';

			return (
				<>
						<div className="col-md-8 resultwinnerWraper" style={{borderRight:'2px solid #666'}}>
							<div className="text-center">
								<div>{winner}</div>
								<img src={`/assets/images/casino/${cards?.card1?.fullcard}.png`} className="casinoResultImg"/>
							</div>
						</div>
					<div className="col-md-4 resultDetailBox">
						<div><span>Winner</span> : {winner} </div>
						<div><span>Odd </span>: {(cards?.card1?.evenOdd == 'odd')&&'Yes'}</div>
						<div><span>Color </span>: {cards?.card1?.cardColor}</div>
						<div><span>Card </span>: {cards?.card1?.realCard}</div>
					</div>
				</>
			)
		} else if(data?.gtype == t20Odds) {
			return (
				<>
					<div className="col-md-4 text-center">
						<div>Player A</div>
						<div style={{display:'flex',borderRight:'2px solid #666'}}>
							{(result == 1)&&
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
							}
							{['1','2','3']?.map((v)=>(
								<div className="col-md-4 resultwinnerWraper">
									<div className="text-center">
										<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="col-md-4 text-center">
						<div>Player B</div>
						<div style={{display:'flex'}}>
							{(result == 3)&&
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
							}
							{['4','5','6']?.map((v)=>(
								<div className="col-md-4 resultwinnerWraper">
									<div className="text-center">
										<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
									</div>
								</div>
							))}
						</div>
					</div>
					
					<div className="col-md-4 resultDetailBox">
						<div><span>Winner</span> : {(result == "1")?'Player A':'Player B'} </div>
					</div>
				</>
			)
		} else if(data?.gtype == testtp) {
			return (
				<>
					<div className="col-md-3 text-center">
						<div>Tiger</div>
						<div style={{display:'flex',borderRight:'2px solid #666'}}>
							{(result == '11')?
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
								:<div className="casinoResultImg" />
							}
							{['1','2','3']?.map((v)=>(
								<div className="col-md-3 resultwinnerWraper">
									<div className="text-center">
										<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="col-md-3 text-center">
						<div>Lion</div>
						<div style={{display:'flex'}}>
							{(result == '21')?
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
								:<div className="casinoResultImg" />
							}
							{['4','5','6']?.map((v)=>(
								<div className="col-md-3 resultwinnerWraper">
									<div className="text-center">
										<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
									</div>
								</div>
							))}
						</div>
					</div>
					
					<div className="col-md-3 text-center">
						<div>Dragon</div>
						<div style={{display:'flex'}}>
							{(result == '31')?
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
								:<div className="casinoResultImg" />
							}
							{['7','8','9']?.map((v)=>(
								<div className="col-md-3 resultwinnerWraper">
									<div className="text-center">
										<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
									</div>
								</div>
							))}
						</div>
					</div>
					
					<div className="col-md-3 resultDetailBox">
						<div><span>Winner</span> : {(result == "11")?'Tiger':(result == "21")?'Lion':'Dragon'} </div>
					</div>
				</>
			)
		} else if(data?.gtype == Tp1Day) {
			let bf = (data?.bf)?JSON.parse(data?.bf):{};
			let bf1 = (bf?.length)?bf[0]:{};
			let bf2 = (bf?.length)?bf[1]:{};
			
			let cards = {
				card1 : breakCardColor(bf1?.C1),
				card2 : breakCardColor(bf1?.C2),
				card3 : breakCardColor(bf1?.C3),
				card4 : breakCardColor(bf2?.C1),
				card5 : breakCardColor(bf2?.C2),
				card6 : breakCardColor(bf2?.C3),
			}

			return (
				<>
					<div className="col-md-4 text-center">
						<div>Player A</div>
						<div style={{display:'flex',borderRight:'2px solid #666'}}>
							{(result == "1")&&
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
							}
							{['1','2','3']?.map((v)=>(
								<div className="col-md-4 resultwinnerWraper">
									<div className="text-center">
										<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="col-md-4 text-center">
						<div>Player B</div>
						<div style={{display:'flex'}}>
							{(result == "2")&&
								<img src={`/assets/images/casino/winner.png`} className="casinoResultImg winner"/>
							}
							{['4','5','6']?.map((v)=>(
								<div className="col-md-4 resultwinnerWraper">
									<div className="text-center">
										<img src={`/assets/images/casino/${cards?.[`card${v}`]?.fullcard}.png`} className="casinoResultImg"/>
									</div>
								</div>
							))}
						</div>
					</div>
					
					<div className="col-md-4 resultDetailBox">
						<div><span>Winner</span> : {(result == "1")?'Player A':'Player B'} </div>
					</div>
				</>
			)
		}
	}

	return (
	<Modal show={(Object.keys(data)?.length > 0)} contentClassName="result-modal-content" dialogClassName="loginModal-box modal-dialog modal-xl"  onHide={onClose}>
				{/* <div className="modal-content" style={{backgroundColor:'#2e3439',padding:'0px'}}> */}
		<div className="modal-body" style={{padding:'5px'}}>
			<button type="button" onClick={()=>{
				onClose()
			}} className="btn-close" data-bs-dismiss="modal"><i className="fa-solid fa-xmark"></i></button>
			<div style={{"backgroundColor":"#00676c",paddingLeft:'10px',paddingTop:'5px',borderRadius:'10px'}}>
				<h5 style={{color:'#ffffff'}}> {getHeading()} </h5>
			</div>
			<div className="col-md-12 row">
				<div className="col-md-6">Round-Id: {data?.mid}</div>
				<div className="col-md-6" style={{textAlign:'right'}}> Match Time: {gettime()}</div>
			</div>
			<div className="col-md-12 row" style={{display:'flex',alignItems:'center'}}>
				{calculateResult()}
			</div>
		</div>
				{/* </div> */}
	</Modal>
)}

export default ResultModal;