import { createStore ,applyMiddleware} from 'redux';

import {BuyerAuthReducer} from './buyer/AuthReducers';
import {MainReducer} from './buyer/reducers';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default to localStorage
import { composeWithDevTools } from '@redux-devtools/extension';
import { combineReducers } from 'redux'
import encryptTransforms from './buyer/middleware/redux-persist'; // Import the encryption transform
   
const authPersistConfig = {
    key: 'auth',
    storage:storage,
    transforms: [encryptTransforms], // Apply the encryption transform
}
  
const rootReducer = combineReducers({
    buyerAuth: persistReducer(authPersistConfig, BuyerAuthReducer),
    buyerGlobal: MainReducer,
})

let store = createStore(rootReducer,composeWithDevTools(applyMiddleware(thunk)));
let persistor = persistStore(store);

export { store, persistor };