import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { updateUserPassword } from 'config/helper';
import { POST, GET } from 'config/api';
import global from '../../../../config/global';




function ChangePassword(props) {
    const [isLoading, setIsLoading] = useState(false);
    const { buyerdata } = useSelector((state) => state.buyerAuth);

    const dispatch = useDispatch();

    const [currentpasssword,setCurrentPasssword] = useState('');
    const [newpassword,setNewPassword] = useState('');
    const [conpassword,setConPassword] = useState('');
    const [currentpasssword_error,setCurrentPassswordError] = useState('');
    const [newpassword_error,setNewPasswordError] = useState('');
    const [conpassword_error,setConPasswordError] = useState('');

    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);


    const updatePassword = async ()=>{
        // console.log('asdfasdf',currentpasssword,buyerdata.oldpass);
        setCurrentPassswordError('');
        setNewPasswordError('');
        setConPasswordError('');
        if(currentpasssword=='')
        {
            toast.info("Please Enter Current Passsword", { autoClose: 2000 });
            setCurrentPassswordError('Please Enter Current Passsword');
        }
        else if(currentpasssword!=buyerdata.oldpass){
            toast.info("Current Passsword Is Wrong", { autoClose: 2000 });
        }
        else if(newpassword=='')
        {
            toast.info("Please Enter New Password", { autoClose: 2000 });
            setNewPasswordError('Please Enter New Password');
        }
        else if(conpassword=='')
        {
            toast.info("Please Enter Confirm Password", { autoClose: 2000 });
            setConPasswordError('Please Enter Confirm Password');
        }
        else if(newpassword!=conpassword)
        {
            toast.info("Password MisMatch", { autoClose: 2000 });
            setConPasswordError('Password MisMatch');
        }
        else
        {
            setCurrentPassswordError('');
            setNewPasswordError('');
            setConPasswordError('');
            let body = {
                id:buyerdata.id,
                currentpasssword:currentpasssword,
                newpassword:newpassword
            };
            setIsLoading(true);
            POST(`${global.W_BASEURL}customer/auth/changeCustomerPassword`, body).then((res) => {
                if (res.data.status == 200) {
                        let updateData = {
                            id:buyerdata.id,
                            currentpasssword:currentpasssword,
                            newpassword:newpassword
                        }
                        updateUserPassword(updateData);
                        toast.info("Password Changed Successfully Please Login Again!", { autoClose: 2000 });
                        dispatch({type:'BLOGOUT'});
                        //history.goBack();


                }
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                console.log(err)
            })
        }
    }

    return (
        <div className="col-lg-10">
            <div className=''>
                <div className="homeWrapper-box pageClass mt-3">
                <div className="row mb-4" style={{padding:'10px'}}>
                    <div className="col">
                        <div className="siteTitle pageClass pb-0">
                            <h1>Change Password</h1>
                        </div>
                    </div>
                </div>

                <div className="pageClass" style={{padding:'10px'}}>
                    <div className="loginBox">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label>Old Password</label>
                                    <input type="text" onChange={(event) => { setCurrentPasssword(event.target.value) }} className="form-control" placeholder="Enter old password"/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label>New Password</label>
                                    <input type="text" onChange={(event) => { setNewPassword(event.target.value) }} className="form-control" placeholder="Enter new password"/>
                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label>Re-Type Password</label>
                                    <input type="text" onChange={(event) => { setConPassword(event.target.value) }} className="form-control" placeholder="Enter Re-type password"/>
                                </div>
                            </div>
                        </div>
                        <div className="link-btn">
                            <a  onClick={() => { updatePassword() }}><i className="fa-solid fa-pen-to-square mr10"></i> Update</a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );

}
export default ChangePassword;