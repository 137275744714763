import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react'
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { faWallet, faHouse, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core';
import { useDispatch, useSelector } from 'react-redux';
import { subscribe, unsubscribe } from 'config/event';

const RightPanel = ({ title }) => {
	const history = useNavigate();
	library.add(faWallet, faHouse, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);
	const dispatch = useDispatch();
	const buyerdata = useSelector((state) => state?.buyerAuth?.buyerdata);

	useEffect(() => {
		subscribe('openSideBar', (data) => {
			setShow(true);
		});

		return () => {
			unsubscribe('openSideBar');
		}
	}, []);

	const logout = () => {
		dispatch({ type: 'BLOGOUT' });
	}

	const getWallet = (type) => {
		let exposure = (buyerdata?.exposure_bal) ? buyerdata?.exposure_bal : 0;
		let wallet = (buyerdata?.rel_wallet) ? buyerdata?.rel_wallet : 0
		return (type == 'exp') ? exposure : (type == 'total') ? wallet : wallet - exposure;
	}
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	const handleOnCLick = (route) => {
		if (route) {
			setShow(false)
			history(route);
		}
	}

	return (
		<>
			<div className="col-auto">
				<div className="">
					<Link to={'/deposit'} className="small-btn" >
						<i className="fa-solid fa-money-bill-transfer"></i> Deposit
					</Link>
				</div>
				<div className="mt-2"> {/* Optional: add margin-top for spacing */}
					<Link to={'/withdrawal'} className="small-btn-red">
						<i className="fa-solid fa-wallet"></i> Withdraw
					</Link>
				</div>
			</div>
			<div onClick={() => { handleShow(true) }} className="col-auto">
				<div className="showMoney-btn"><a href="javascript:void(0);"><i className="fa-solid fa-money-bill"></i> {getWallet()}</a></div>
				<div className="showMoney-btn expBtn"><a href="javascript:void(0);">Exp. {getWallet('exp')}</a></div>
			</div>
			<Offcanvas show={show} onHide={handleClose} placement="end" className="bg-white rightSticky-menu">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title className='text-black'>Profile</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className="col-lg-10">
						<div className='pageRight-mainWrapper'>
							<div className="main-content-wrapper">
								<div className="profileMain-container pageClass">
									<div className="profileInfo-table pageClass">
										<table className="table table-dark table-hover">
											<tr>
												<th colSpan='2'>{buyerdata.name}</th>
											</tr>
											<tr>
												<th>Wallet Balance</th>
												<td>{getWallet('total')}</td>
											</tr>
											<tr>
												<th>Available Balance </th>
												<td>{getWallet()}</td>
											</tr>
											{/* <tr>
											<th>Winnings</th>
											<td>+13,500</td>
										</tr> */}
											<tr>
												<th>Exposure</th>
												<td className="lossText">{getWallet('exp')}</td>
											</tr>
											<tr style={{ cursor: 'pointer' }} onClick={() => {
												handleOnCLick('/stackes')
											}}>
												<th colSpan="2">Set Stake Value</th>
											</tr>

											<tr style={{ cursor: 'pointer' }} onClick={() => {
												handleOnCLick('/change_password')
											}}>
												<th colSpan="2">Change Password</th>
											</tr>
											{/* <tr>
											<th colSpan="2">Total Rolling</th>
										</tr> */}
											{/* <tr>
											<th colSpan="2">Remaining Rolling</th>
										</tr> */}
										</table>
									</div>
									<div className="profileUser-box pageClass">
										<div className="row gx-2">
											<div className="col-6">
												<div className="profileItem-box">
													<a className='rightNavbarText' onClick={() => { handleOnCLick("/deposit_history") }}>
														<img src="/assets/images/profile-icon1.png" alt="Vasudev" />
														<p>Account <br /> Statement</p>
													</a>
												</div>
											</div>

											<div className="col-6">
												<div className="profileItem-box">
													<a className='rightNavbarText' onClick={() => { handleOnCLick("/bat_history") }}>
														<img src="/assets/images/profile-icon4.png" alt="Vasudev" />
														<p>Bat History</p>
													</a>
												</div>
											</div>
											<div className="col-6">
												<div className="profileItem-box">
													<a className='rightNavbarText' onClick={() => { handleOnCLick("/withdrawal") }}>
														<img src="/assets/images/profile-icon2.png" alt="Vasudev" />
														<p>Withdraw</p>
													</a>
												</div>
											</div>
											<div className="col-6">
												<div className="profileItem-box">
													<a className='rightNavbarText' onClick={() => { handleOnCLick("/deposit") }}>
														<img src="/assets/images/profile-icon3.png" alt="Vasudev" />
														<p>Deposit</p>
													</a>
												</div>
											</div>
											<div className="col-6">
												<div className="profileItem-box">
													<a className='rightNavbarText' onClick={() => { handleOnCLick("/UpdateProfile") }}>
														<img src="/assets/images/profile-icon5.png" alt="Vasudev" />
														<p>Edit <br /> Profile</p>
													</a>
												</div>
											</div>
											<div className="col-6">
												<div className="profileItem-box">
													<a className='rightNavbarText' onClick={() => { handleOnCLick("/bat_history") }}>
														<img src="/assets/images/profile-icon6.svg" alt="Vasudev" />
														<p>Notification <br /> History</p>
													</a>
												</div>
											</div>
											<div onClick={() => {
												logout();
											}} className="col-12 text-center mt-2 mb-2">
												<div className="link-btn1"><a href="javascript:void(0);"><i className="fa-solid fa-right-to-bracket"></i> Log Out</a></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	)
}

export default RightPanel;
