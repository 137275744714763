import React, { memo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import RenderBatModal from './BatModal';
import RenderMatchItem from './Component/RenderMatchItem';


const memoCompare = (prevProps, nextProps) =>{
    if((prevProps?.marketOdds?.isUpdated_back != nextProps?.marketOdds?.isUpdated_back) || (prevProps?.marketOdds?.isUpdated_lay != nextProps?.marketOdds?.isUpdated_lay)) {
        return false;
    } else {
        return true;
    }
}

const OddBat = (props) => {

    let marketOdds = (props?.marketOdds?.data)?props?.marketOdds:{data:[]};
    let match_id = props?.match_id;
    let series_id = props?.series_id;
    let bat_format = props?.bat_format;
    let heading = props?.heading;
    let matchname = props?.matchname;
    let liveOdds = (props?.marketOdds?.liveOdds)?props?.marketOdds?.liveOdds:{};

    const [forceRefresh,setforceRefresh] = useState(false)
    const [showBatModal,setshowBatModal] = useState(false);
    const [modalItem,setmodalItem] = useState(false);
    
    return (
        (marketOdds?.data?.length > 0)&&
            <div className="liveGame-section pageClass">
                <div className="liveGame-titleBox pageClass">
                    <div className="siteTitle pageClass pb-0">
                        {(matchname)?
                            <h2><span style={{fontSize:'15px'}}>{matchname} {`( ${heading} )`}</span></h2>
                        :
                            <h2><span style={{fontSize:'15px'}}>{heading}</span></h2>
                        }
                        
                    </div>
                </div>                                
                <div className="priceTable-boxMain pageClass">
                <div className="matchList-grid pageClass">
                    <div className="row">
                        <div className="col-6 align-self-center">
                            <div className="matchTitle-box pageClass">
                                <a href="javascript:void(0);">
                                </a>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="priceGroup-box pageClass newList-gridBox">
                                <div className="row gx-2">
                                        <div className="col">
                                            <div className="backLayHeading priceColum-row" style={{display:'flex',justifyContent:'flex-end'}}>
                                                <div className={`priceColum-grid`}>
                                                    <a className={`bluePrice`} style={{borderTopLeftRadius:'15px'}}>Back</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="backLayHeading priceColum-row">
                                                <div className={`priceColum-grid`}>
                                                    <a className={`PinkPrice`} style={{borderTopRightRadius:'15px'}}>Lay</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                    {marketOdds?.data?.map((value,index)=>(
                        <RenderMatchItem key={`oddbat${index}`} isUpdated={value?.isUpdated} bat_format={bat_format} showBatModal={showBatModal} value={value} onBetClick={(item)=>{
                            setmodalItem({
                                ...item,
                                series_id:series_id,
                                match_id:match_id,
                                runnerList:marketOdds?.data,
                            });
                            setshowBatModal(true);
                        }} forceRefresh={forceRefresh} />
                    ))}
                    <div style={{backgroundColor:'#ffffff',textAlign:'end',color:'red'}}>{marketOdds?.msg}</div>
                </div>
            {(showBatModal)&&<RenderBatModal liveBhav={liveOdds} onClose={(msg)=>{
                setshowBatModal(false);
                if(msg) {
                    setforceRefresh(!forceRefresh);
                }
            }} showBatModal={showBatModal} modalItem={modalItem}/>}

        </div>
    )
}


export default OddBat
