import React, { memo, useState } from 'react'
import global from 'config/global';

const RenderTv = memo(({match_id,mode}) => {
    if(match_id) {
        return (
            <div className={`liveGame-section pageClass ${(mode=='desktop')?'desktopTv-box':'mobileTv-box'}`} style={{marginBottom:'0px'}}>
                <div className="liveGame-titleBox pageClass">
                    <div className="siteTitle pageClass pb-0">
                        <h2>Live TV</h2>
                    </div>
                </div>
                <div className="liveTv-show pageClass">
                    <iframe src={`${global.LIVETV}${match_id}`} />
                </div>
            </div>
        )
    } else {
        return null;
    }
});

export default RenderTv;
