let header = '#045662';
let sideBar = '#000000';
let txtWhite = '#ffffff';
let txtYellow = '#ebb553';
let txtWhiteDull = '#757575';
let txtGray = '#aaaaaa';
let bat_back_1 = '#72bbef';
let bat_back_2 = '#92c9f0';
let bat_back_3 = '#b1d6f0';
let bat_lay_1 = '#faa9ba';
let bat_lay_2 = '#f8bbc8';
let bat_lay_3 = '#f5cdd5';

let backgroundColor = '#353535';


const color = {
    header:header,
    sideBar:sideBar,
    txtWhite:txtWhite,
    txtYellow:txtYellow,
    txtWhiteDull:txtWhiteDull,
    txtGray:txtGray,
    backgroundColor:backgroundColor,
    bat_back_1:bat_back_1,
    bat_back_2:bat_back_2,
    bat_back_3:bat_back_3,
    bat_lay_1:bat_lay_1,
    bat_lay_2:bat_lay_2,
    bat_lay_3:bat_lay_3,
}

export default color;