import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Global from './../../../../config/global';
import Footer from "../../includes/footer";
import { MatchList } from '../customer/matchComponent/MatchList';
import { GET } from 'config/api';
import HomeSlider from 'component/web/includes/homeslider';
import TopGames from 'component/web/includes/topGames';
import CasinoList from '../customer/casinolist';
import NavBarPanel from 'component/web/includes/navBarPanel';
import { useParams } from 'react-router-dom';




function Home(props) {
  const {event_id} = useParams()
  const [showPopup, setShowPopup] = useState(true);
  const [state, setState] = useState({
    isLoading: false,
    header_slider: [],
    footer_slider: [],
    block_1: [],
    block_2: [],
    aboutData: []

  });
  const [activeEvents, setActiveEvents] = useState([]);

  useEffect(()=>{
    fetchActiveEvents()
  },[event_id]);

  useEffect(() => {
    const isFirstTime = !localStorage.getItem('popupShown');
    if (isFirstTime) {
      setShowPopup(true);
      localStorage.setItem('popupShown', 'true');
    }
    homePageData();
  }, [],);

  const closePopup = () => {
    setShowPopup(false);
  };

  const homePageData = async () => {
    await Axios.get(`${Global.W_BASEURL}front/home/fetchHomeData`).then((res) => {
      if (res.data.status == 200) {
        let data = res.data.data;
        let obj = {
          header_slider: ['banner_01.png'],
          footer_slider: [],
          block_1: [],
          block_2: [],
          aboutData: []
        };
        for (let item of data) {
          obj[item.type] = item?.image?.split(',');
        }
        obj.aboutData = res?.data?.homeAbout;
        setState(obj)
      }
    })
  }


  // useEffect(() => {
  // 	fetchHomeSLider();
  // }, [],);

  // const fetchHomeSLider = async () => {
  // 	Axios.get(`${Global.W_BASEURL}front/home/fetchHeaderData`).then((res) => {
  // 		if (res.data.status == 200) {
  // 			let data = res.data.data;
  // 			stateHandler("header_slider", data.banner);
  // 		}
  // 	})
  // }
  const fetchActiveEvents = async () => {
		GET(`${Global.W_BASEURL}front/home/fetchActiveEvents`).then((res) => {
            
            if (res.data.status == 200) {
                let data = res.data.data;
                data = data?.filter((v)=>((v.event_id == event_id) || (event_id == undefined && v.event_id == 4)));
                setActiveEvents(data);
			}
		})
	}

  return (

    <div>
      {/* {showPopup && <Popup onClose={closePopup} />} */}

      <HomeSlider List={state?.header_slider} />

      <NavBarPanel ishome={true} />
      <div className="pageContent-container pageClass homeSection1" >
        <div className="container">
          <div className='homeGamesTable pageClass'>
            {activeEvents?.map((v, i) => (<MatchList ishome={true} index={i} key={`matchList${i}`} value={v} />))}
          </div>
        </div>
      </div>

      <TopGames />
      <div id="liveCasino" className="pageContent-container homeSection3 pageClass">
        <div className="container">
          <div className='pageClass'>
            <CasinoList isHome={true} />
          </div>
        </div>
      </div>

      <div className="pageContent-container pt-0 homeAbout-section pageClass">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="aboutVasu-pic pageClass">
                <img src={`${Global.IMAGE_PATH}${state.aboutData?.[0]?.image}`} className="w-100" alt="Vasudev" />
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="aboutVasu-intro pageClass">
                <div className="siteTitle pageClass">
                  <h1 className='text-black'>About Vasudev</h1>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: state.aboutData?.[0]?.matter || "No data available",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Home;
