import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';

import { POST } from 'config/api';
import { truncate } from 'config/helper';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faCircle } from '@fortawesome/free-solid-svg-icons';
import global from 'config/global';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from 'component/web/includes/header';

function DepositHistory(props) {
    library.add(faCircle);
    const [historyList,setHistoryList] = useState([]);

    useEffect(()=>{
        getBattingHistory()
    },[])
    const getBattingHistory = () => {
        let body = {
            index:0,
            limit:10,
        }
        POST(`${global.W_BASEURL}customer/transaction/getDepositHistory`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                setHistoryList(data?.list)
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }
    // getBattingHistory

    // const getStatusColor = (status) =>{
    //     switch (status) {
    //         case 'success':
    //             return '#1e9c13';
    //             break;
    //         case 'pending':
    //             return '#e35327';
    //             break;
    //         case 'cancel':
    //             return '#9e0909';
    //             break;
    //         default:
    //             return '#FFFFFF';
    //             break;
    //     }
    // }

    return (
            <div className="col-lg-10">
                <div className='pageRight-mainWrapper'>
                    <div className="liveGame-section pageClass">
                        <div className="liveGame-titleBox pageClass">
                            <div className="siteTitle pageClass pb-0">
                                <h2>Deposit History</h2>
                            </div>
                        </div>
                        <table className="table table-dark matchBet-table font-14">
                            <thead>
                                <tr style={{textAlign:'center'}}>
                                    <th>S/N</th>
                                    <th>Txn Id</th>
                                    <th>UTR</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {historyList?.map((item,index)=>(
                                <tr style={{textAlign:'center'}}>
                                    <th style={{'width':'50px'}}>{index + 1}</th>
                                    <td>{item?.id}</td>
                                    <td>{item?.utr_no}</td>
                                    <td>{item?.amount}</td>
                                    <td >{item?.status}</td>
                                    <td>{moment(item?.created_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                </tr>
                            ))}
                                {(historyList?.length == 0)&&
                                <tr style={{textAlign:'center'}}>
                                    <td colSpan="6">No Record Found!</td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div> 
                </div>
            </div>
    );

}
export default DepositHistory;